<template>
	<!-- begin: card-list -->
	<ul class="card-list">
		<li class="card-item" v-for="(item, idx) in items" :key="idx">
			<router-link :to="`/supportbiz/intrd/${item.intrdId}/exm/${item.exmId}?suptExmCd=${item.suptExmCd}&pageNo=${pageNo}&sortCd=${sortCd ?? ''}`" class="card-item__link">
				<div class="card-item__image">
					<img :src="item.exmUrl" :alt="item.exmTit" />
				</div>
				<!-- <div class="card-item__title" v-if="showTitle">
					{{ item.exmTit }}
				</div> -->
			</router-link>
		</li>
	</ul>
	<!-- end: card-list -->
</template>

<script>
export default {
	name: 'SupportBizIntrdCardList',
	props: {
		items: Array,
		viewLink: String,
		showTitle: Boolean,
		pageNo: Number,
		sortCd: String
	},
};
</script>

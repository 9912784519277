<template>
	<main class="page-container" id="supportBiz-home">
		<div class="page-header">
			<div class="page-component">
				<h2 class="page-header-title">지원사업 소개</h2>
				<p class="page-header-desc">
					소상공인 단계별 지원사업을 확인하고<br />
					내게 필요한 사업을 신청하세요
				</p>
			</div>
		</div>

		<!-- <div class="page-header-banner">
			<picture>
				<source media="(max-width: 800px)" srcset="@/assets/img/@tmp/banner_sample_mo.jpg" />
				<source media="(min-width: 801px)" srcset="@/assets/img/@tmp/banner_sample_pc.jpg" />
				<img src="@/assets/img/@tmp/banner_sample_pc.jpg" alt="" />
			</picture>
		</div> -->

		<!-- 지원사업 LNB -->
		<lnb-support-biz-intrd className="" :openVisible="treeMenuVisible" />
		<!-- //지원사업 LNB -->

		<div class="page-body">
			<div class="container">
				<!-- 상단 콘텐츠 -->
				<section class="home-section support-smb-top">
					<h2 class="support-smb-top__title">
						{{ intrdInfo.intrdTit ?? '' }}
					</h2>
					<dl class="support-smb-top__dl">
						<dt v-if="intrdInfo.intrdSmalTit">{{ intrdInfo.intrdSmalTit }}</dt>
						<dd v-if="intrdInfo.intrdCn">{{ intrdInfo.intrdCn }}</dd>
					</dl>
					<dl class="support-smb-top__dl">
						<dt>사업 소개</dt>
						<dd>
							<!-- 사업소개 내용 -->
							<img v-for="item in intrdInfo.imgFiles" :src="item.intrdFileUrl" :alt="item.intrdFileUrlNm" />
						</dd>
					</dl>
				</section>
				<!-- //상단 콘텐츠 -->

				<section class="home-section" v-for="item in ctgrItems" :key="item.id">
					<div class="home-section-header" v-if="item.items && item.items.length > 0">
						<div class="header-column">
							<h3 class="home-section-title">{{ item.text }}</h3>
						</div>
						<div class="header-column">
							<router-link :to="`/supportbiz/intrd/${intrdId}/exms?suptExmCd=${item.id}`" class="link-more-icon">
								<span class="text">더보기</span>
							</router-link>
						</div>
					</div>
					<div class="card-list-wrapper" v-if="item.items && item.items.length > 0">
						<!-- <div class="list-header">
							<template>
								<div class="header-column"></div>
								<div class="header-column">
									<ul class="sort-list">
										<li class="sort-item is-active"><button type="button" @click.prevent="selectSort('')">최신순</button></li>
										<li class="sort-item"><button type="button" @click.prevent="selectSort('I')">인기순</button></li>
									</ul>
								</div>
							</template>
						</div> -->
						<common-list :div-class="['list-body']" :is-loading="isLoading" :is-no-result="isNoResult">
							<support-biz-intrd-card-list :items="item.items" :showTitle="true" :pageNo="1" />
						</common-list>
					</div>
				</section>
				<div class="support-smb-footer" v-if="intrdInfo.applyLink">
					<div class="buttons">
						<a :href="intrdInfo.applyLink" target="_blank" class="button-default is-large is-rounded is-secondary">신청</a>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_SPRT_BIZ_INTRD, ACT_GET_SPRT_BIZ_INTRD_EXM_LIST } from '@/store/_act_consts';
import { getItems, lengthCheck, setPaging, tryResCallback, queryToValue } from '@/assets/js/utils';
import NoResult from '@/components/common/NoResult';
import CommonList from '@/components/common/CommonList';
import SupportBizIntrdCardList from '@/components/support/SupportBizIntrdCardList';
import LnbSupportBizIntrd from '@/components/support/LnbSupportBizIntrd';
import { mapGetters } from 'vuex';
import menus from '@/assets/js/menus';
import { MUT_SPRT_BIZ_TREE_MENU_VISIBLE } from '@/store/_mut_consts';

export default {
	name: 'supportBizIntrd',
	components: {
		LnbSupportBizIntrd,
		NoResult,
		CommonList,
		SupportBizIntrdCardList,
	},
	data: () => ({
		hasMore: false,
		intrdId: 0,
		intrdInfo: {},
		totalCount: 0,
		pageSize: 4,
		pageNo: 1,
		sortCd: '',
		isNoResult: false,
		isLoading: false,
		plygrndCtgrDcd: 1,
		ctgrItems: [
			{ id: '10', text: '', items: [] },
			{ id: '20', text: '', items: [] },
		],
		tryCount: 0,
		menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	watch: {
		$route(to) {
			this.init();
		},
	},
	computed: {
		...mapGetters('common', ['isMobile']),
		...mapGetters('support', ['treeMenuVisible']),
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			// 최소는 LEFT메뉴 펼쳐진 상태로
			this.$store.commit(`support/${MUT_SPRT_BIZ_TREE_MENU_VISIBLE}`, queryToValue(this.$route.params.intrdId, true, 0) === 0);
			this.intrdId = queryToValue(this.$route.params.intrdId, true, 0);
			this.intrdInfo = {};
			this.ctgrItems = [
				{ id: '10', text: '', items: [] },
				{ id: '20', text: '', items: [] },
			];
			this.getData();
		},
		getData() {
			this.$store
				.dispatch(`support/${ACT_GET_SPRT_BIZ_INTRD}`, {
					intrdId: this.intrdId,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.intrdInfo = getItems(res)[0];
					}
				})
				.catch((e) => {
					console.error(e);
				});

			this.getExmList();
		},
		getExmList() {
			this.ctgrItems.forEach((ctgr) => {
				this.$store
					.dispatch(`support/${ACT_GET_SPRT_BIZ_INTRD_EXM_LIST}`, {
						intrdId: this.intrdId,
						params: {
							suptExmCd: ctgr.id,
							pageNo: this.pageNo,
							pageSize: this.pageSize,
						},
					})
					.then((res) => {
						if (lengthCheck(res)) {
							const items = getItems(res);
							ctgr.text = items[0].suptExmNm;
							ctgr.items = items;
							//this.ctgrItems.push({id: exmCd, text: items[0].suptExmNm, items})
						}
					});
			});
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>

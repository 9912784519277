<template>
	<div class="support-smb-lnb" :class="className">
		<button type="button" class="button-support-smb" @click.prevent="toggleLnb()"><i class="icon-nav-list">메뉴 열기</i></button>
		<nav class="support-smb-nav" :class="{ 'is-active': treeMenuVisible }">
			<header class="support-smb-nav__header">
				<h3>2024년 소상공인 온라인 판로지원사업</h3>
				<button type="button" class="button-close" @click.prevent="toggleLnb()"><i class="icon-close">창닫기</i></button>
			</header>
			<div class="support-smb-nav__body">
				<ul>
					<li v-for="(item, idx) in treeMenus" :key="idx" :class="{ 'is-expand': item.expand }">
						<a href="#" @click.prevent="expandMenu(idx)">{{ item.text }}</a>
						<transition name="slide">
							<div v-if="item.menuList && item.expand">
								<ul>
									<li v-for="(menu, idx2) in item.menuList" :key="idx2" :class="{ mt1: menu.intrdId == '' && idx2 > 0 }">
										<router-link v-if="menu.intrdId" :to="`/supportbiz/intrd/${menu.intrdId}`">{{ menu.text }}</router-link>
										<span v-else class="text">{{ menu.text }}</span>
										<ul v-if="menu.menuList">
											<li v-for="(subMenu, idx3) in menu.menuList" :key="idx3">
												<router-link :to="`/supportbiz/intrd/${subMenu.intrdId}`">{{ subMenu.text }}</router-link>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</transition>
					</li>
				</ul>
			</div>
			<button type="button" class="button-support-smb" @click.prevent="toggleLnb()"><i class="icon-nav-close">메뉴 닫기</i></button>
		</nav>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SPRT_BIZ_TREE_MENU } from '@/store/_act_consts';
import { getCheckItems, getItems, isSuccess, lengthCheck } from '@/assets/js/utils';
import { MUT_SPRT_BIZ_TREE_MENU_LIST, MUT_SPRT_BIZ_TREE_MENU_VISIBLE } from '../../store/_mut_consts';

export default {
	name: 'LnbSupportBizIntrd',
	props: {
		className: String,
		openVisible: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data: () => ({
		showLnb: true,
	}),
	computed: {
		...mapGetters('support', ['treeMenus']),
		...mapGetters('support', ['treeMenuVisible']),
	},
	created() {
		//1. 메뉴 리스트가 없거나
		//2. 리스트 목록 OR 상세 에서 HOME으로 이동시 조회
		if (!(this.treeMenus && this.treeMenus.length > 0) || this.$route.name === 'supportBizIntrd') this.getItems();
	},
	mounted() {
		// this.showLnb = this.openVisible; // state로 관리
	},
	methods: {
		getItems() {
			this.$store
				.dispatch(`support/${ACT_GET_SPRT_BIZ_TREE_MENU}`, {})
				.then((res) => {
					if (lengthCheck(res)) {
						//this.items = getItems(res);
						this.$store.commit(`support/${MUT_SPRT_BIZ_TREE_MENU_LIST}`, getItems(res));
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		toggleLnb() {
			//this.showLnb = !this.showLnb; // state로 관리
			this.$store.commit(`support/${MUT_SPRT_BIZ_TREE_MENU_VISIBLE}`, !this.treeMenuVisible);
		},
		expandMenu(el) {
			this.treeMenus[el].expand = !this.treeMenus[el].expand;
		},
	},
};
</script>

<style>
.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
	max-height: 1000px;
	overflow: hidden;
}

.slide-enter,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
